/* eslint-disable react/react-in-jsx-scope */
import KitchenOutlinedIcon from '@mui/icons-material/KitchenOutlined';
import LocalLaundryServiceOutlinedIcon from '@mui/icons-material/LocalLaundryServiceOutlined';
import CoffeeMakerOutlinedIcon from '@mui/icons-material/CoffeeMakerOutlined';
import ElevatorOutlinedIcon from '@mui/icons-material/ElevatorOutlined';
import NestCamWiredStandOutlinedIcon from '@mui/icons-material/NestCamWiredStandOutlined';
import CellWifiOutlinedIcon from '@mui/icons-material/CellWifiOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import ChairAltOutlinedIcon from '@mui/icons-material/ChairAltOutlined';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import TableBarOutlinedIcon from '@mui/icons-material/TableBarOutlined';
import SanitizerOutlinedIcon from '@mui/icons-material/SanitizerOutlined';
import DoorFrontOutlinedIcon from '@mui/icons-material/DoorFrontOutlined';
import { BED_TYPES, GENDER_PREFERENCE, ROOM_TYPES, TENANT_TYPES } from './constant';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CommitOutlinedIcon from '@mui/icons-material/CommitOutlined';
import femaleIcon from '../assets/images/female 1.svg'
import maleIcon from '../assets/images/male 1.svg'
import bothIcon from '../assets/images/both 1.svg'

export const selectAmenitiesIcon = (item) => {
    switch (item) {
        case "cctv":
            return <NestCamWiredStandOutlinedIcon />
        case "wifi":
            return <CellWifiOutlinedIcon />;
        case "daily room cleaning ":
            return <CleaningServicesOutlinedIcon />
        case "daily dustbin cleaning ":
            return <SanitizerOutlinedIcon />;
        case "weekly once washroom cleaning":
            return <ChairAltOutlinedIcon />
        case "bed":
            return <BedOutlinedIcon />;
        case "almirah":
            return <DoorFrontOutlinedIcon />;
        case "fridge":
            return <KitchenOutlinedIcon />;
        case "washing machine":
            return <LocalLaundryServiceOutlinedIcon />;
        case 'water purifier':
            return <CoffeeMakerOutlinedIcon />;
        case 'lift':
            return <ElevatorOutlinedIcon />;
        case "center table":
            return <TableBarOutlinedIcon />;
        default:
            return <CommitOutlinedIcon />;
    }
};

export const selectProfessionIcon = (item) => {
    switch (item) {
        case TENANT_TYPES.STUDENT:
            return <SchoolOutlinedIcon className="me-2" />
        case TENANT_TYPES.WORKING_PROFESSIONAL:
            return <BusinessCenterOutlinedIcon className="me-2" />;
        default:
            return <PersonOutlineOutlinedIcon className="me-2" />;
    }
};

export const getFloorName = (name) => {
    switch (name) {
        case 0:
            return 'Ground';
        case 1:
            return '1st';
        case 2:
            return '2nd';
        case 3:
            return '3rd';
        default:
            return `${name}th`
    }
}

export const selectGenderPreferenceIcon = (item) => {
    switch (item) {
        case GENDER_PREFERENCE.BOYS_PG:
            return <img
                style={{
                    marginTop: "0.2em",
                    backgroundColor: '#7E8B93',
                    marginRight: '4px'
                }}
                src={maleIcon}
                alt="male"
                height="15px"
                width="15px"
            />
        case GENDER_PREFERENCE.GIRLS_PG:
            return <img
                style={{
                    marginTop: "0.2em",
                    backgroundColor: '#7E8B93',
                    marginRight: '4px'
                }}
                src={femaleIcon}
                alt="female"
                height="15px"
                width="15px"
            />
        default:
            return <img
                style={{
                    marginTop: "0.2em",
                    backgroundColor: '#7E8B93',
                    marginRight: '4px'
                }}
                src={bothIcon}
                alt="both"
                height="15px"
                width="15px"
            />
    }
};

export const fetchBedType = (bedCount) => {
    switch (bedCount) {
        case 1:
            return BED_TYPES.SINGLE;
        case 2:
            return BED_TYPES.DOUBLE;
        case 3:
            return BED_TYPES.TRIPLE;
        default:
            return `${bedCount} Sharing`
    }
}

export const fetchRoomType = (roomTypeId) => {
    switch (roomTypeId) {
        case 1:
            return ROOM_TYPES.AC;
        case 2:
            return ROOM_TYPES.NON_AC;
        default:
            return ''
    }
}