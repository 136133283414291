import PropTypes from 'prop-types';
import React from 'react';
import {
  Col, Modal, ModalBody, Row,
} from 'reactstrap';

export default function SystemNotification({ show, onAcceptClick = () => { }, onCloseClick = () => { }, subject, body }) {
  const handleClick = () => {
    onAcceptClick();
    onCloseClick();
  }
  return (
    <Modal isOpen={show}
      centered>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: '9em', color: 'orange' }}
              />
              <h6>
                {subject}
              </h6>
              <p>
                {body}
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="yellow_gradient_btn btn btn-success btn-lg ms-2"
                onClick={handleClick}
              >
                OK
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

SystemNotification.propTypes = {
  show: PropTypes.bool,
  onAcceptClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  subject: PropTypes.string,
  body: PropTypes.string,
};
