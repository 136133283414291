import React, { useEffect, useState } from 'react';

import {
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    FormFeedback
} from 'reactstrap';

import { handleRecoverPassword } from './api';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import logo from '../../assets/images/logo1.png';
import { Link } from 'react-router-dom';
import VerifyToken from '../../components/PassWordRecovery/verifyOtp';
import { PORTAL } from '../../common/constant';

function RecoverPassword() {
    const [isVerify, setIsVerify] = useState(false);
    document.title = ' Recover Password | DStayZ';
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            contact_number: '',
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            contact_number: Yup.string().matches(/^[0-9]{10}$/, 'Contact number must be 10 digits').required('Contact number is required')
        }),
        onSubmit: async (values) => {
            try {
                await handleRecoverPassword({
                    portal: PORTAL.OWNER_PORTAL,
                    contact_number: values.contact_number.toString(),
                    isEmail: false
                })
                setIsVerify(true);
            } catch (error) {
                console.log(error)
            }
        }
    });

    useEffect(() => {
        document.body.className = 'authentication-bg';
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = '';
        };
    });

    return (
        <div className="account-pages my-5 pt-sm-5">
            {!isVerify ? (
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <div>
                                <div className="card">
                                    <Link to="/" className="mt-2 d-block auth-logo">
                                        <img src={logo} alt="" height="40" className="logo logo-dark" />
                                    </Link>
                                    <div className="card-body">

                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Reset Password</h5>
                                            <p className="text-muted">Reset Password with DStayZ.</p>
                                        </div>

                                        <div className="p-2 mt-2">
                                            <div className="alert alert-success text-center mb-4" role="alert">
                                                Enter your contact number and instructions will be sent to you!
                                            </div>
                                            <Form className="form-horizontal" onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}>

                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="useremail">Contact No</Label>
                                                    <Input
                                                        name="contact_number"
                                                        className="form-control"
                                                        id="contactNo"
                                                        placeholder="Enter contact number"
                                                        onChange={validation.handleChange}
                                                        type="text"
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.contact_number}
                                                        invalid={
                                                            !!(validation.touched.contact_number && validation.errors.contact_number)
                                                        }
                                                    />
                                                    {validation.touched.contact_number && validation.errors.contact_number && (
                                                        <FormFeedback type="invalid">{validation.errors.contact_number}</FormFeedback>
                                                    )}
                                                </FormGroup>

                                                <div className="mt-3 text-end">
                                                    <button
                                                        className="btn btn-primary w-sm waves-effect waves-light"
                                                        type="submit"
                                                        disabled={!validation.isValid}
                                                    >
                                                        Reset
                                                    </button>
                                                </div>
                                                <div className="mt-4 text-center">
                                                    <p className="mb-0">
                                                        Remember It ?
                                                        <Link to="/login" className="fw-medium text-primary"> Signin </Link>
                                                    </p>
                                                </div>
                                            </Form>
                                        </div>

                                    </div>
                                </div>
                                {/* <div className="mt-5 text-center">
                                    <p>
                                        ©
                                        {' '}
                                        {new Date().getFullYear()}
                                        {' '}
                                        Minible. Crafted with
                                        {' '}
                                        <i className="mdi mdi-heart text-danger" />
                                        {' '}
                                        by Themesbrand
                                    </p>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <VerifyToken generateDetails={validation.values} setIsVerify={setIsVerify} />
            )}
        </div>
    );
}

export default RecoverPassword;
