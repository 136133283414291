import React, { useEffect, useState } from "react";
import BedMatrixBhk from "./bedMatrixBhk";
import { PROPERTY_TYPES } from "../../common/constant";
import BedMatrixHome from "./bedMatrixHome";
import { getBedMatrix } from "../../api/owner";

function BedMatrixDetails({ propertyType, propertyId }) {
    const [propertyList, setPropertyList] = useState()

    useEffect(() => {
        if (propertyId) {
            fetchBedMatrixOwner()
        }

    }, [propertyId])

    const fetchBedMatrixOwner = async () => {
        try {
            const response = (await getBedMatrix(propertyId)).data.data
            setPropertyList(response)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className="bedMatrixPg">

            {/* Render Bed matrix Bhk  */}
            {(propertyType === PROPERTY_TYPES.APARTMENT ?
                <BedMatrixBhk propertyDetails={propertyList} /> :
                <BedMatrixHome propertyDetails={propertyList} />
            )}
        </div>
    );
}

export default BedMatrixDetails;