import React, { useEffect, useState } from "react";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import dayjs from "dayjs";
import ViewNote from "../Common/ViewNote";
import NodataFound from "../Common/NoDataFound";
import NoDataIcon from "../../assets/images/no-data.png";
import { getScheduleVisitList } from "../../api/owner";
import { DateRangePicker } from "react-date-range";
import { DEFAULT_DATE_FORMAT } from "../../common/constant";
import { IconButton, TextField } from "@mui/material";
import { Clear } from "@mui/icons-material";

export default function ScheduleVisit({ propertyId }) {
    const [notes, setNote] = useState("");
    const [noteView, setNoteView] = useState(false);
    const [scheduleVisitList, setScheduleVisitList] = useState();
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [pickerOpen, setPickerOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState()

    useEffect(() => {
        if (propertyId) {
            fetchScheduleListForOwner()
        }

    }, [propertyId])

    const fetchScheduleListForOwner = async (params = '') => {
        try {
            const response = (await getScheduleVisitList(propertyId, { ...params })).data.data
            setScheduleVisitList(response)
        } catch (err) {
            console.log(err)
        }
    }

    const toggleNote = () => {
        setNoteView(!noteView);
    };

    const getStatusColor = (status) => {
        return status === "Not Visited" ? "#FAA61B" : "#579e55";
    };

    const handleDateRangeChange = async (item) => {
        setSelectionRange(item.selection);
        const formattedStartDate = dayjs(item.selection.startDate).format(DEFAULT_DATE_FORMAT);
        const formattedEndDate = dayjs(item.selection.endDate).format(DEFAULT_DATE_FORMAT);
        setSelectedDate(`${formattedStartDate} - ${formattedEndDate}`)
        await fetchScheduleListForOwner({
            filter_fields: ['visit_datetime'],
            filter_inputs: [`${formattedStartDate}~${formattedEndDate}`],
        })
        setPickerOpen(false);
    };

    return (
        <div className="rent_history_tab rent_slips_tab vacant_history_tab">
            <div className="mb-3">
                <TextField
                    size="small"
                    className="global_search"
                    type="text"
                    name='leaving_date'
                    value={selectedDate || ''}
                    label="Visiting Date Range"
                    InputProps={{
                        readOnly: true, endAdornment: selectedDate ? (
                            <IconButton size="small" onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                setSelectedDate()
                                fetchScheduleListForOwner()
                            }}>
                                <Clear />
                            </IconButton>
                        ) : undefined
                    }}
                    onClick={() => setPickerOpen(!pickerOpen)}
                />
                {pickerOpen && (
                    <DateRangePicker
                        ranges={[selectionRange]}
                        moveRangeOnFirstSelection={false}
                        retainEndDateOnFirstSelection={true}
                        onChange={handleDateRangeChange}
                    />
                )}
            </div>
            <div className='complain_ticket'>
                <div className='overall_tickets list-inline'>

                    {(!scheduleVisitList || scheduleVisitList?.length === 0) && (
                        <div style={{ textAlign: 'center', height: '500px' }}>
                            <NodataFound text='No Schedule Visit Request available to show.' icon={NoDataIcon} />
                        </div>
                    )}
                    {scheduleVisitList?.length > 0 && scheduleVisitList?.map((data, index) => (
                        <div className='single_ticket' key={index}>
                            <div className='single_ticket_header d-flex justify-content-between align-items-center'>
                                <div className='ticket_id'><b>{data.fullname}</b></div>
                                <div className="rs_header_right d-flex">
                                    <div
                                        className="ticket_id me-3"
                                        style={{
                                            borderRight: "1px solid #ddd",
                                            paddingRight: "12px",
                                            paddingLeft: '12px',
                                            color: 'white',
                                            background: getStatusColor(data.status)
                                        }}
                                    >
                                        {data.status}
                                    </div>
                                    <div
                                        className="rent_status"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setNote(data?.note?.description || "");
                                            toggleNote();
                                        }}
                                    >
                                        <VisibilityOutlinedIcon /> View Notes
                                    </div>
                                </div>
                            </div>
                            <div className='single_ticket_body'>
                                <div className="d-grid three_grid_cols schedule_respo_blocks">
                                    <div className='resolved_by particular_block_border date_ico'>
                                        <div className='responsive_word_break'><CalendarTodayOutlinedIcon />Visiting Date:</div> <b>{dayjs(data.visit_datetime).format('DD-MM-YYYY')}</b>
                                    </div>
                                    <div className='create_date particular_block_border'>
                                        <div className='responsive_word_break'><PersonOutlineIcon />Contact Person&apos;s Name:</div> <b>{data?.property?.care_taker_name}</b>
                                    </div>
                                    <div className='resolved_by particular_block_border'>
                                        <div className='responsive_word_break'><LocalPhoneOutlinedIcon />Contact Person&apos;s Number:</div> <b>{data?.property?.care_taker_contact_no}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>


                {noteView && (
                    <ViewNote
                        isAddNoteOpen={noteView}
                        isAddNotetoggle={toggleNote}
                        value={notes}
                        add_title={"Note"}
                    />
                )}
            </div>
        </div>
    );
}