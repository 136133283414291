import React, { useEffect } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { useProfile } from '../../components/Hooks/UserHooks';
import { logoutUser } from '../../store/actions';
import axiosInstance from '../../utils/axios/auth';
import { useDispatch } from 'react-redux';
import { removeUserDetails, storeUserDetails } from '../../store/auth';
import { AutoLogout } from './AutoLogout';
import { toast } from 'react-toastify';

function Authmiddleware(props) {
  const { user, token } = useProfile()
  const dispatch = useDispatch()
  const navigate = useNavigate();

  // call auto logout function
  AutoLogout()

  useEffect(() => {
    if (!user && token) {
      axiosInstance.get('/user/me').then((response) => {
        dispatch(storeUserDetails({ user: response.data.data }))
      }).catch((err) => {
        console.error(err)
        // dispatch(logoutUser());
        toast.error(err?.response?.data?.message ?? err?.message)
        navigate('/login')
      })
    } else if (!user && !token) {
      dispatch(logoutUser());
      dispatch(removeUserDetails())
    }
  }, [token, user]);
  if (!token) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{user && props.children}</>;
}

export default Authmiddleware;