import React, { useEffect, useState } from "react";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { Link } from 'react-router-dom';
import KingBedOutlinedIcon from '@mui/icons-material/KingBedOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NodataFound from "../Common/NoDataFound";
import { BED_TYPES } from "../../common/constant";
import dayjs from "dayjs";
import NoDataIcon from "../../assets/images/no-data.png";
import { getVacantList } from "../../api/owner";
import Search from "../Common/GlobalSearch";
import { IconButton, TextField } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import { Clear } from "@mui/icons-material";
import { Col, Row } from "reactstrap";
import { format } from "date-fns";

export default function VacantRequest({ propertyId }) {
    const [vacantList, setVacantList] = useState()
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [pickerOpen, setPickerOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState()

    useEffect(() => {
        if (propertyId) {
            fetchVacantListForOwner()
        }

    }, [propertyId])

    const fetchVacantListForOwner = async (searchItem = '') => {
        try {
            const params = searchItem ? { ...searchItem } : ''
            const response = (await getVacantList(propertyId, params)).data.data
            setVacantList(response)
        } catch (err) {
            console.log(err)
        }
    }

    const fetchBedType = (bedCount) => {
        switch (bedCount) {
            case 1:
                return BED_TYPES.SINGLE;
            case 2:
                return BED_TYPES.DOUBLE;
            case 3:
                return BED_TYPES.TRIPLE;
            default:
                return ''
        }
    }

    const handleDateRangeChange = async (item) => {
        setSelectionRange(item.selection);
        const formattedStartDate = format(selectionRange.startDate, 'dd-MM-yyyy');
        const formattedEndDate = format(selectionRange.endDate, 'dd-MM-yyyy');
        setSelectedDate(`${formattedStartDate} - ${formattedEndDate}`)
        await fetchVacantListForOwner({
            filter_fields: ['leaving_date'],
            filter_inputs: [`${formattedStartDate}~${formattedEndDate}`],
        })
        setPickerOpen(false);
    };

    return (
        <div className="rent_history_tab rent_slips_tab vacant_history_tab">
            <div className='complain_ticket'>
                <Row className="row d-flex">
                    <Col lg={8}>
                        <Search listCall={(data) => fetchVacantListForOwner(data)} />
                    </Col>

                    <Col lg={4}>
                        <TextField
                            size="small"
                            sx={{
                                width: '80%'
                            }}
                            className="global_search"
                            type="text"
                            name='leaving_date'
                            value={selectedDate || ''}
                            label="Leaving Date Range"
                            InputProps={{
                                readOnly: true, endAdornment: selectedDate ? (
                                    <IconButton size="small" onClick={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        setSelectedDate()
                                        fetchVacantListForOwner()
                                    }}>
                                        <Clear />
                                    </IconButton>
                                ) : undefined
                            }}
                            onClick={() => setPickerOpen(!pickerOpen)}
                        />
                        {pickerOpen && (
                            <DateRangePicker
                                ranges={[selectionRange]}
                                moveRangeOnFirstSelection={false}
                                retainEndDateOnFirstSelection={true}
                                onChange={handleDateRangeChange}
                            />
                        )}
                    </Col>

                </Row>
                <div className='overall_tickets list-inline'>
                    {vacantList?.length === 0 && (
                        <div style={{ textAlign: 'center', height: '500px' }}>
                            <NodataFound text='No Pg Vacant Request available to show.' icon={NoDataIcon} />
                        </div>
                    )}
                    {vacantList?.length > 0 && vacantList.map((data, index) => (
                        <div className='single_ticket' key={index}>
                            <Link to='/payout-history-detail'>
                                <div className='single_ticket_header d-flex justify-content-between align-items-center'>
                                    <div className='ticket_id'><b>{data?.tenant?.user?.name}</b></div>
                                </div>
                            </Link>
                            <div className='single_ticket_body'>
                                <div className="d-grid four_grid_cols">
                                    <div className='create_date particular_block_border'>
                                        <div className='responsive_word_break'><KingBedOutlinedIcon />Bed Type:</div> <b>{fetchBedType(data.bed?.room?.bed_count)}</b>
                                    </div>
                                    <div className='resolved_by particular_block_border'>
                                        <div className='responsive_word_break'><HomeOutlinedIcon />Room Type:</div> <b>{data.bed?.room?.room_type?.name}</b>
                                    </div>
                                    <div className='resolved_by particular_block_border '>
                                        <div className='responsive_word_break'><CalendarTodayOutlinedIcon />Leaving Date:</div> <b>{dayjs(data?.leaving_date).format('DD-MM-YYYY')}</b>
                                    </div>
                                    <div className='resolved_by particular_block_border'>
                                        <div className='responsive_word_break'><PersonOutlinedIcon />Acknowledgement Status:</div> <b>{data?.acknowledge_status}</b>
                                    </div>
                                </div>
                                <div className='resolved_by admin_comment_border d-flex'>
                                    <div className='responsive_word_break'><PersonOutlinedIcon />Leaving Reason:</div> <b>{data?.note?.description}</b>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}