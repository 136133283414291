import React, { useState, useRef, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { DEFAULT_DATETIME_FORMAT, DEFAULT_PER_PAGE_VALUE } from '../../common/constant';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

import {
  getAllNotificationList,
} from '../../api/notification';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import NodataFound from '../../components/Common/NoDataFound';
import moment from 'moment';
import PaginationComponent from '../../components/Common/PaginationComponent';
import { getLocalTimeBody, ucFirst } from '../../helpers/string_helper';
import NoDataIcon from "../../assets/images/no-data.png";

export default function Notifications() {
  document.title = 'Notifications | Dstayz';

  // const [isExistingDetails, setExistingDetails] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE);
  const [update, setUpdate] = useState(0);

  const currentUserId = useSelector(
    (state) => state?.AuthLogin?.auth?.user?.id,
  );

  const paramsObject = useRef({
    page: 1,
    limit: DEFAULT_PER_PAGE_VALUE,
    filter_fields: [],
    filter_inputs: [],
  });

  // const [details, setDetails] = useState({});
  // const toggleDetailsDrawer = async (data = {}) => {
  //   setDetails(data);
  //   setExistingDetails(!isExistingDetails);
  //   if (data?.id && !data.read) {
  //     await markAsReadNotificationApi({ ids: [data.id] });
  //     fetchNotificationList(paramsObject.current);
  //   }
  // };

  const fetchNotificationList = async (params) => {
    try {
      setLoading(true);
      params.filter_fields = ['receiver_id', ...params.filter_fields];
      params.filter_inputs = [currentUserId, params.filter_inputs];
      const response = (await getAllNotificationList(params)).data?.data;
      setPaginationMeta(response.meta);
      let level = (response.meta.current_page - 1) * response?.meta?.per_page;
      const resultData = response.data.map((res) => {
        return {
          id: res.id,
          index_no: ++level,
          ...res,
        };
      });
      setNotificationList(resultData);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const paginationModelMethod = async (data) => {
    if (data?.page) {
      paramsObject.current = {
        ...paramsObject.current,
        page: data.page,
        limit: rowsPerPage,
      };
    } else {
      paramsObject.current = {
        ...paramsObject.current,
        page: 1,
        limit: data?.limit,
      };
      setUpdate((prev) => prev + 1);
      setRowsPerPage(data?.limit);
    }
    await fetchNotificationList(paramsObject.current);
  };

  useEffect(() => {
    fetchNotificationList(paramsObject.current);
  }, []);

  return (
    <div className='page-content'>
      <Container fluid>
        <div className='complain_ticket'>
          <div className='inner_page_header'>Notifications</div>
          <div className='overall_tickets list-inline'>
            {loading && (
              <div style={{ textAlign: 'center', height: '500px' }}>
                <CircularProgress />
              </div>
            )}
            {notificationList?.length === 0 && !loading && (
              <div style={{ textAlign: 'center', height: '500px' }}>
                <NodataFound text='No notification to show.' icon={NoDataIcon} />
              </div>
            )}
            {notificationList.map((data) => (
              <div className='single_ticket' key={data?.id}>
                <div className='single_ticket_header single_message_header d-flex justify-content-between align-items-center flex-wrap'>
                  <div className='message_id'>
                    <CircleNotificationsIcon style={{ color: 'white' }} />
                    {data?.index_no}
                    {'. '}
                    {ucFirst(data?.notification?.content?.subject)}
                  </div>
                  <div className='message_time d-flex flex-column align-items-baseline '>
                    <span>
                      <AdminPanelSettingsOutlinedIcon />{' '}
                      {data?.notification?.sender?.name ?? 'DStayZ'}
                    </span>
                    <span>
                      <CalendarTodayOutlinedIcon />{' '}
                      {moment(data?.created_at).format(DEFAULT_DATETIME_FORMAT)}
                    </span>
                  </div>
                </div>
                <div className='single_message'>
                  {/* <ReactQuill
                    value={data.notification.content.body}
                    readOnly={true}
                    theme={'bubble'}
                  /> */}
                  <div style={{ fontSize: '12px', padding: '15px' }} dangerouslySetInnerHTML={{ __html: getLocalTimeBody(data?.notification?.content?.body) }}></div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {notificationList?.length > 10 && (
          <div>
            <div className='tabsBody'>
              <Row>
                <Col className='col-12'>
                  <Card className='table_card_holder mt-2'>
                    <CardBody>
                      <div className='numbers_pagination_holder'>
                        <PaginationComponent
                          paginationMeta={paginationMeta}
                          handleChangePagination={paginationModelMethod}
                          update={update}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )}

      </Container>

      {/* {isExistingDetails && (
        <DetailDrawer
          isOpen={isExistingDetails}
          toggleDrawer={toggleDetailsDrawer}
          details={details}
        />
      )} */}
    </div>
  );
}
