import React, { useState, useEffect } from "react";
import FloorBedMatrix from "./floorBedMatrix";
import { getFloorName } from "../../common/amenities_icon";

function BedMatrixHome({ propertyDetails }) {
    const [floorRowData, setFloorRowData] = useState([]);

    useEffect(() => {
        if (propertyDetails?.length > 0) {
              const result = propertyDetails.map((el) => {
                    const res = el.name.slice(0, -2);
                    return {
                        ...el,
                        name: parseInt(res)
                    }
                })
            const sortedFloor = result.toSorted((a, b) => a.name > b.name ? 1 : -1).map((it) => it);
            setFloorRowData(sortedFloor)
        }
    }, [propertyDetails])

    return (
        <div>
            <div className="activeProperties activePropertyBedmatrix activePropertyBedmatrixBhk">
                <div className="bm_wrapper">
                    <div className="bm_body_cols_outer">
                        <div className="bm_body_cols d-flex flex-column">
                            {floorRowData.map((item, index) => (
                                <div className="bm_single_row d-flex" key={index}>
                                    <div className="bm__flat">
                                        <FloorBedMatrix floor={getFloorName(item?.name)} rooms={item.rooms} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BedMatrixHome;
