import React, { useEffect, useState } from "react";
import { Card, Container } from 'reactstrap';
import Appartment from "../../assets/images/appartment.png";
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { useNavigate } from 'react-router-dom';
import { getPropertyOwner } from "../../api/owner";
import { useProfile } from "../../components/Hooks/UserHooks";
import AddressComponent from "../../components/Common/Address";
import dayjs from "dayjs";
import { getS3BaseUrl } from "../../helpers/string_helper";
import NodataFound from "../../components/Common/NoDataFound";
import NoDataIcon from "../../assets/images/no-data.png";
import { selectGenderPreferenceIcon } from "../../common/amenities_icon";

export default function TenantDashboard() {
    const [proprtyOwner, setPropertyOwner] = useState();
    const { user } = useProfile()
    const s3BaseUrl = getS3BaseUrl()

    useEffect(() => {
        fetchPropertyOwner()
    }, [])

    const fetchPropertyOwner = async () => {
        try {
            const response = (await getPropertyOwner(user?.property_owner?.id)).data.data
            const result = response.property.map((item) => {
                return {
                    ...item,
                    name: item.name,
                    address: <AddressComponent address={item.address} />,
                    totalTenants: item?._count?.tenant,
                    pictures: item.pictures,
                    listedSince: dayjs(item.created_at).format('DD.MM.YYYY'),
                }
            })
            setPropertyOwner(result)
        } catch (err) {
            console.log(err)
        }
    }

    const navigate = useNavigate();

    return (
        <div className="page-content">
            <Container fluid>
                <div className="owner_dashboard">
                    {(!proprtyOwner || proprtyOwner?.length === 0) && (
                        <div style={{ textAlign: 'center', height: '500px' }}>
                            <NodataFound text='No property available to show.' icon={NoDataIcon} />
                        </div>
                    )}
                    {proprtyOwner?.map((property, index) => (
                        <Card key={index} className="mb-4" onClick={() => {
                            navigate('/owner-dashboard/owner-property-details', {
                                state: {
                                    property: JSON.stringify(property)
                                }
                            })
                        }} style={{ cursor: 'pointer' }}>
                            <div className="appartment_details d-grid two_main_grid_cols" >
                                <div className="d-grid single_item" style={{
                                    display: 'inline-block'
                                }}>
                                    <div className="appartment_img mx-2">

                                        {property?.default_image?.name ? (
                                            <img src={`${s3BaseUrl}${property?.default_image?.location}`} alt={property?.default_image?.name} />
                                        ) : <img src={Appartment} alt={property.name} />}

                                    </div>
                                </div>
                                <div className="child_single_item">
                                    <h5>{property.name}</h5>
                                    <div className="d-flex">
                                        <FmdGoodOutlinedIcon />
                                        <p className="m-0">{property.address}</p>
                                    </div>
                                    <div className="d-flex mt-2">
                                        {selectGenderPreferenceIcon(property?.gender_preference)}
                                        <p className="m-0">Gender Preference <b>{property.gender_preference}</b></p>
                                    </div>
                                    <div className="d-flex mt-2">
                                        <GroupsOutlinedIcon />
                                        <p className="m-0">Total Tenant <b>{property.totalTenants}</b></p>
                                    </div>
                                    <div className="d-flex listed_text mt-2">
                                        <p className="mb-1" style={{ color: 'red', fontWeight:'600' }}>Listed Since {property.listedSince}</p>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    ))}
                </div>
            </Container>
        </div>
    )
}
