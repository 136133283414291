import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import { toast } from "react-toastify";

export default function Search({ listCall, params, disabled }) {
  const [mounted, setMounted] = useState(false);
  const [query, setQuery] = React.useState("");

  const fetchData = async (data) => {
    try {
      let queryParams = {};
      if (Array.isArray(data.params)) {
        queryParams = {
          search: data.query,
          others: data?.params,
        };
      } else {
        queryParams = {
          ...data.params,
        };
        if (Object.keys(queryParams).includes("search") && data.query) {
          queryParams.search = data.query;
        } else if (!Object.keys(queryParams).includes("search") && data.query) {
          queryParams.search = data.query;
        } else if (data?.params?.search && !data.query) {
          queryParams.search = data.query;
        }
      }
      const res = await listCall(queryParams);
      return res;
    } catch (err) {
      toast.error("Encountered problem with fetching records.");
    }
  };
  const debouncedFetchData = useCallback(
    debounce((data, cb) => {
      fetchData(data, query).then((response) => {
        cb(response);
      });
    }, 1200),
    []
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      debouncedFetchData({ params, query }, (res) => {
        console.log(res)
      });
    }
  }, [query, debouncedFetchData]);
  return (
    <form>
      <i className="fas fa-search global_search_icon"></i>
      <input
        className="global_search"
        disabled={disabled ?? false}
        onChange={(event) => {
          setQuery(event.target.value);
        }}
        type="text"
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
        name="search"
        placeholder="Search.."
      />
    </form>

    // <div className='position-relative'>
    //   <i className="fas fa-search global_search_icon"></i>
    //   <TextField
    //     placeholder="Search..."
    //     className='global_search'
    //     disabled={disabled ?? false}
    //     size="small"
    //     onChange={(event) => { setQuery(event.target.value) }}
    //   // InputProps={{
    //   //   startAdornment: (
    //   //     <InputAdornment position="start">
    //   //       <span className="uil-search" />
    //   //     </InputAdornment>
    //   //   ),
    //   //   endAdornment: (
    //   //     <div className="flexbox_holder loadingIcon_pos_abs">
    //   //       {loading ? <CircularProgress color="inherit" size={20} /> : ""}
    //   //     </div>
    //   //   ),
    //   // }}
    //   />

    // </div>
  );
}
